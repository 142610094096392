<script setup>
  import { ref, onMounted, computed, inject } from 'vue'
  import { useAuthStore } from '@/stores/auth.store'
  import { fetchWrapper } from '@/helpers/fetch-wrapper'
  import { useI18n } from 'vue-i18n'
  import { getLanguageIdByName } from '@/helpers/utils'
  import ComboBox from '@/components/ComboBox.vue'
  import { changeLocale } from '@formkit/vue'
  import supportedLanguages from '../locales/languages.json'
  import { alertController } from '@ionic/vue'
  import { loadLocaleMessages } from '../i18n'
  const i18n = inject('i18n')
  const { t, locale } = useI18n()
  const authStore = ref(false)
	const showDeleteDataModal = ref(false)
  authStore.value = useAuthStore()

  const profile = ref({})
  const new_language = ref('')
  const missing_new_language = ref(false)
  const missing_fluency = ref(false)
  const new_language_level = ref('')
  const isLoading = ref(true)
  const timezones = ref([])
  async function load_profile() {
    const response = await fetchWrapper.get(`/profile/`)
    console.log(response)
    profile.value = response
    timezones.value = await fetchWrapper.get(`/profile/timezone/`)
    isLoading.value = false
  }
  onMounted(async () => {
    await load_profile()
  })
  const available_languages = computed(() => {
    if (!profile.value || !profile.value.all_languages || !profile.value.languages) {
      return []
    }
    const used_languages = profile.value.languages.map(language => language.language)
    used_languages.push(profile.value.native_language)
    return profile.value.all_languages.filter(language => !used_languages.includes(language.language))
  })
  async function change_language_level(language, level) {
    language.level = level
    await fetchWrapper.patch(`/profile/language/`, { language: language.language, level: level })
  }
  async function change_learning_language(language) {
    profile.value.learning_language = language
    await fetchWrapper.patch(`/profile/`, { learning_language: language })
    await load_profile()
  }
  async function change_show_animations(selected) {
    profile.value.show_animations = selected
    await fetchWrapper.patch(`/profile/`, { show_animations: selected })
    await load_profile()
  }
  async function change_native_language(language) {
    await fetchWrapper.patch(`/profile/`, { native_language: language })
    await load_profile()
    const alert = await alertController.create({
      header: t('profile.display-language-change'),
      message: t('profile.should-we-change-your-display-language-to-language', { language: t(`language.name.${getLanguageIdByName(language)}`) }),
      buttons: [
        {
          text: t('profile.no'),
          role: 'cancel',
          cssClass: 'secondary'
        },
        {
          text: t('profile.yes'),
          handler: () => {
            var locale = supportedLanguages[language]
            if (locale) {
              updateLanguage(locale)
            }
            profile.value.native_language = language
          }
        }
      ]
    });

    await alert.present();
  }
  async function add_language() {
    if (!new_language.value) {
      missing_new_language.value = true
    }
    else {
      missing_new_language.value = false
    }
    if (!new_language_level.value) {
      missing_fluency.value = true
    }
    else {
      missing_fluency.value = false
    }
    if (missing_new_language.value || missing_fluency.value) {
      return
    }
    const new_language_record = await fetchWrapper.patch(`/profile/language/`, { language: new_language.value, level: new_language_level.value })
    profile.value.languages.push(new_language_record)
  }
  async function remove_language(language) {
    await fetchWrapper.post(`/profile/language/remove/`, { language: language.language })
    profile.value.languages = profile.value.languages.filter(item => item.id !== language.id)
  }
  function set_timezone(tz) {
    console.log("set_timezone", tz.name)
    const new_tz = tz.name
    if (!new_tz || new_tz == profile.value.timezone) {
      return
    }
    profile.value.timezone = new_tz
    fetchWrapper.patch(`/profile/`, { timezone: new_tz })
  }
  function set_max_new_cards_per_day() {
    fetchWrapper.patch(`/profile/`, { max_new_cards_per_day: profile.value.max_new_cards_per_day })
  }

  function updateLanguage (language) {
    loadLocaleMessages(i18n, language)
    locale.value = language
    localStorage.setItem('language', language)
    changeLocale(language)
    // config.locale = language
  }
  async function deleteData() {
    await fetchWrapper.get(`/delete-data/`)
    alert(t('profile.your-data-will-be-deleted-in-the-next'))
    authStore.value.logout()
  }

</script>

<template>
  <nice-header />
  <loading-overlay v-model:active="isLoading" :can-cancel="true" is_full_page="false" loader="dots" :opacity="0.8" />
  <section class="my-5">
    <div class="col-lg-10 offset-lg-1 col-xs-12 col-sm-12" v-if="!isLoading">
      <div class="bg-white border-0 rounded border-light p-1 p-sm-2 p-md-5 w-100 fmxw-500">
        <div class="text-center text-md-center mb-4 mt-md-0 col-12">
          <h1 class="mb-0 h1-title">{{ t('profile.profile') }}</h1>
          <hr />
        </div>
        <div class="d-flex justify-content-center gap-2"></div>
        <div class="row">
          <div class="col-lg-8 offset-lg-2 col-xs-12 col-sm-12">
            <div class="mb-3">
              <label for="name">{{ t('profile.first-name') }}</label>
              <input type="text" class="form-control" id="first_name" v-model="profile.first_name" />
            </div>
            <div class="mb-3">
              <label for="name">{{ t('profile.last-name') }}</label>
              <input type="text" class="form-control" id="last_name" v-model="profile.last_name" />
            </div>
            <div class="mb-3">
              <label for="email">{{ t('profile.email') }}</label>
              <input type="email" class="form-control" id="email" v-model="profile.email" />
            </div>
            <div class="mb-3">
              <router-link :to="{ name: 'ChangePassword' }">{{ t('profile.change-password') }}</router-link>
            </div>
            <div class="mb-3">
              <label for="max_new_cards_per_day">{{ t('profile.maximum-new-cards-per-day') }}</label>
              <input type="max_new_cards_per_day" class="form-control" id="max_new_cards_per_day" v-model="profile.max_new_cards_per_day" @change="event => set_max_new_cards_per_day(event.target.value)" />
            </div>
            <div class="mb-3">
              <label for="show_animations">{{ t('profile.show-animations') }}</label>
              <select class="form-select" id="level" v-model="profile.show_animations" @change="event => change_show_animations(event.target.value)">
                <option value="true">{{ t('profile.yes') }}</option>
                <option value="false">{{ t('profile.no') }}</option>
              </select>
            </div>
            <div class="mb-3">
              <label for="timezone">{{ t('profile.timezone') }}</label>
              <ComboBox :options="timezones" @selected="set_timezone" :maxItem="10000" :initialvalue="profile.timezone" placeholder="Start typing..." >
                <template v-slot:option="{ option }">
                  <div class="col-md-8 text-start">{{ option.label }}</div>
                  <div class="col-md-3 text-end">{{ option.time }}</div>
                </template>
              </ComboBox>
            </div>
            <div class="mb-3">
              <label for="native_language">{{ t('profile.native-language') }}</label>
              <select class="form-select" id="level" v-model="profile.native_language" @change="event => change_native_language(event.target.value)">
                <option v-for="language in profile.all_languages" v-bind:key="language.id" v-bind:value="language.language">
                  {{ t(`language.name.${language.id}`) }}
                </option>
              </select>
            </div>
            <div class="mb-3">
              <label for="learning_language">{{ t('profile.current-learning-language-used-for-new-chats') }}</label>
              <select class="form-select" id="level" v-model="profile.learning_language" @change="event => change_learning_language(event.target.value)">
                <option v-for="language in profile.languages" v-bind:key="language.id" v-bind:value="language.language">
                  {{ t(`language.name.${language.language_id}`) }}
                </option>
              </select>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-lg-8 offset-lg-2 col-xs-12 col-sm-12"><label>{{ t('profile.what-languages-are-you-interested-in-learning') }}</label><hr /></div>
          </div>
          <div class="row d-none d-sm-none d-md-none col-lg-8 offset-lg-2">
            <div class="col-md-4 offset-md-1"><strong>{{ t('profile.language') }}</strong></div>
            <div class="col-md-4"><strong>{{ t('profile.your-level') }}</strong></div>
          </div>
          <div class="row mt-3" v-for="language in profile.languages" v-bind:key="language.id">
            <div class="col-xs-12 col-sm-12 col-lg-3 offset-lg-2">
              {{ t(`language.name.${language.language_id}`) }}
              <div class="d-inline-flex d-xs-inline-flex d-md-none">
                <router-link :to="{ name: 'LanguageConfig', params: { languageID: language.language_id }}">
                  <i class="bi-pencil-square action-icon-sm" />
                </router-link>
                &nbsp;
                <i v-if="language.language != profile.learning_language" class="bi-trash action-icon-sm" @click="remove_language(language)"/>
              </div>
            </div>
            <div class="col-lg-3 col-xs-12 col-sm-12">
              <select class="form-select" id="level" v-model="language.level" @change="event => change_language_level(language, event.target.value)">
                <option value="beginner">{{ t('profile.beginner') }}</option>
                <option value="intermediate">{{ t('profile.intermediate') }}</option>
                <option value="advanced">{{ t('profile.advanced') }}</option>
                <option value="native">{{ t('profile.native') }}</option>
              </select>
            </div>
            <div class="col-lg-2 d-none d-sm-none d-md-block justify-content-left" style="text-align: left">
              <router-link :to="{ name: 'LanguageConfig', params: { languageID: language.language_id }}">
                <i class="bi-pencil-square action-icon" />
              </router-link>
              &nbsp;
              <i v-if="language.language != profile.learning_language" class="bi-trash action-icon" @click="remove_language(language)"/>
            </div>
          </div>
          <div class="row d-block d-sm-block d-md-block d-lg-none mt-3">
            {{ t('profile.add-new--') }}
            <hr />
          </div>
          <div class="row mt-3">
            <div class="col-lg-3 offset-lg-2 col-xs-12 col-sm-12">
              <select class="form-select" id="new_language" v-model="new_language" placeholder="New language">
                <option disabled selected value="">{{ t('profile.add-new--') }}</option>
                <option v-for="language in available_languages" v-bind:key="language.id" v-bind:value="language.language">
                  {{ t(`language.name.${language.id}`) }}
                </option>
              </select>
              <span v-if="missing_new_language">{{ t('profile.you-must-choose-a-language-to-learn') }}</span>
            </div>
            <div class="col-lg-3 col-xs-12 col-sm-12">
              <select class="form-select" id="new_language_level" v-model="new_language_level" placeholder="Level">
                <option disabled selected value="">{{ t('profile.select-your-current-level') }}</option>
                <option value="beginner">{{ t('profile.beginner') }}</option>
                <option value="intermediate">{{ t('profile.intermediate') }}</option>
                <option value="advanced">{{ t('profile.advanced') }}</option>
              </select>
              <span v-if="missing_fluency">{{ t('profile.you-must-choose-a-level-of-fluency') }}</span>
            </div>
            <div class="col-lg-2 col-2" style="text-align: left">
              <i class="bi-plus-square action-icon" @click="add_language()"/>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-lg-8 offset-lg-2 col-xs-12 col-sm-12">
              <hr />
              <label>{{ t('profile.delete-my-data') }}</label>
              <hr />
              {{ t('profile.please-ensure-you-have-cancelled-your-account-through') }}<button @click="showDeleteDataModal = true" class="btn-primary-cs btn-primary-sm text-white mt-4">{{ t('profile.delete-my-data') }}</button>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-8 offset-lg-2 col-xs-12 col-sm-12">
							<hr />
              {{ t('profile.version') }} 2.3.4
            </div>
          </div>
        </div>
        <nice-footer />
      </div>
    </div>
  </section>
	<CModal :visible="showDeleteDataModal"  class="cancelSubscriptionModal">
		<form @submit.prevent="handleCancellation">
			<CModalHeader>
				<CModalTitle>{{ t('profile.delete-my-data') }}</CModalTitle>
			</CModalHeader>
			<CModalBody>
				<div class="row text-start my-3">
					<p><b>{{ t('profile.were-sorry-to-see-you-go---please') }}</b></p>
        </div>
			</CModalBody>
			<CModalFooter>
				<CButton type="button" color="secondary" @click="() => { showDeleteDataModal = false }">
					{{ t('billing.keep-my-subscription') }}
				</CButton>
				<CButton @click="deleteData" type="submit" color="danger">
					{{ t('profile.confirm-deletion') }}
				</CButton>
			</CModalFooter>
		</form>
  </CModal>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.action-icon {
  font-size: 150%;
  cursor: pointer;
  color: black;
}
.action-icon-sm {
  font-size: 125%;
  cursor: pointer;
  color: black;
}
</style>


