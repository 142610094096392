<script setup lang="ts">
import { IonToolbar, IonButtons, IonPage, IonRouterOutlet, IonMenuButton } from '@ionic/vue';
import { home, bookmarks, clipboard, chatbubbleEllipses, menuOutline } from 'ionicons/icons';
import { ref, inject } from 'vue'
import { useRoute } from 'vue-router'
const $route = useRoute()
import { useAuthStore } from '@/stores/auth.store'
import TabStyleButton from '@/components/TabStyleButton.vue'
const authStore = ref(false)
authStore.value = useAuthStore()
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const popovermanager = inject('popovermanager')
// TODO figure out if this is needed without tabs
function onTabChange(e) {
  console.log('Tab changed', e)
  popovermanager.closeAll()
}
function color(path) {
  // check the path as a substring of the current route
  return $route.path.includes(path) ? 'primary' : 'medium'
}
</script>

<template>
  <ion-page>
    <ion-header>
    </ion-header>
    <ion-content>
      <ion-router-outlet id="main-content" swipe-gesture="false" />
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-buttons>
          <tab-style-button :color="color('dashboard')" tab="tab1" router-link="/dashboard" :icon="home" :label="t('navbar.dashboard')" />
          <tab-style-button :color="color('cards')" tab="tab2" router-link="/cards" :icon="bookmarks" :label="t('navbar.flashcards')" />
          <tab-style-button v-if="authStore.details && authStore.details.course_available" :color="color('course')" tab="tab_study" router-link="/course" :icon="clipboard" :label="t('dashboard.study')" />
          <tab-style-button :color="color('chat')" tab="tab3" router-link="/chat" :icon="chatbubbleEllipses" :label="t('navbar.chat-mode')" />
            <tab-style-button :icon="menuOutline">
            <template #body>
              <ion-menu-button></ion-menu-button>
            </template>
          </tab-style-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>
<style scoped>
ion-buttons {
  flex: 1;
  display: flex;
  justify-content: space-around;
}
</style>
