<script setup>
  import { defineProps } from 'vue'
  import { ref, inject, onMounted } from 'vue'
  import logo from '@/assets/images/lingo-logo.png'
  const config = inject(Symbol.for('FormKitConfig'))
  import { useAuthStore } from '@/stores/auth.store'
  import { useI18n } from 'vue-i18n'
  import flags from '../locales/flags.json'
  const { t, messages, locale } = useI18n({useScope: 'global'})
	const authStore = ref(false)
  const languages = ref(['en', 'es', 'fr', 'ja', 'pl', 'zh', 'it', 'ko'])
  const visible = ref(true)	

  const i18n = inject('i18n')
  import { changeLocale } from '@formkit/vue'
  import supportedLanguages from "../locales/languages.json"
  import { loadLocaleMessages } from '../i18n'
  authStore.value = useAuthStore()

  const props = defineProps({
    needsReload: {
      type: Boolean,
      default: false
    }
  })

  for (const key in supportedLanguages) {
    // if supportedLanguages[key] is already in the languages array, don't add it again
    if (languages.value.includes(supportedLanguages[key])) {
      continue
    }
    languages.value.push(supportedLanguages[key])
  }

  onMounted(() => {
    if (localStorage.getItem('language')) {
      locale.value = localStorage.getItem('language')
      changeLocale(localStorage.getItem('language'))
    } else {
      const browserLanguage = (window.navigator.userLanguage || window.navigator.language)
      const [language, country] = browserLanguage.split('-')
      for (const key in supportedLanguages) {
        if (supportedLanguages[key] === language) {
          console.log('discarded', country, 'and using browser setting to set language to', language)
          localStorage.setItem('language', language)
          locale.value = language
          changeLocale(language)
        }
      }
    }
  })


  function logout () {
    authStore.value.logout()
  }

  async function updateLanguage (language) {
    await loadLocaleMessages(i18n, language)
    locale.value = language
    localStorage.setItem('language', language)
    changeLocale(language)
    config.locale = language
    if (props.needsReload === true) {
      location.reload()
    }
  }

  function getFlag (language) {
    // check for language to exist as a key in the flags dictionary, if it does, return the value
    if (flags[language]) {
      return flags[language].flag
    }
  }
  function languageDisplay (language) {
    // check for language to exist as a key in the flags dictionary, if it does, return the value
    if (flags[language]) {
      return flags[language].display
    }
  }

  console.log('MSG', Object.keys(messages))


</script>


<template>
  <header>
    <CHeader id="headerLogo">
      <CContainer>
        <CHeaderBrand href="#">
          <img :src="logo" alt="Logo" />
          <!--<div v-if="authStore.user">
              user = {{ authStore.user }}
          </div>
          <div v-if="authStore.token">
              token = {{ authStore.token }}
          </div>-->
        </CHeaderBrand>
        <CHeaderToggler @click="visible = !visible">
          <CIcon icon="cil-menu" size="lg" />
        </CHeaderToggler>
        <CCollapse class="header-collapse" :visible="visible">
          <CHeaderNav>
            <CNavItem>
              <CNavLink><router-link to="/">{{ t('navbar.home') }}</router-link></CNavLink>
            </CNavItem>
            <CDropdown v-if="authStore.user && authStore.details && authStore.details.registration_complete" togglerText="Menu" variant="nav-item">
              <CDropdownToggle togglerText="Dropdown">
              <CButton>{{ t('navbar.menu') }}</CButton>
              </CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem><router-link to="/dashboard/">{{ t('navbar.dashboard') }}</router-link></CDropdownItem>
                <CDropdownItem><router-link to="/cards/">{{ t('navbar.flashcards') }}</router-link></CDropdownItem>
                <CDropdownItem v-if="authStore.user && authStore.details && authStore.details.course_available"><router-link to="/course/">{{ t('dashboard.study') }}</router-link></CDropdownItem>
                <CDropdownItem><router-link to="/chat/">{{ t('navbar.chat-mode') }}</router-link></CDropdownItem>
                <CDropdownItem><router-link to="/chat/history/">{{ t('navbar.active-chats') }}</router-link></CDropdownItem>
                <CDropdownItem><router-link to="/vocab/">{{ t('navbar.vocab-list') }}</router-link></CDropdownItem>
                <CDropdownItem><router-link to="/profile/">{{ t('navbar.profile') }}</router-link></CDropdownItem>
                <CDropdownItem><router-link to="/billing/">{{ t('navbar.billing') }}</router-link></CDropdownItem>
                <CDropdownItem><router-link to="/docs/">{{ t('navbar.documentation') }}</router-link></CDropdownItem>
                <CDropdownItem><router-link to="/feedback/">{{ t('navbar.feedback') }}</router-link></CDropdownItem>
                <template v-if="authStore.user && authStore.details">
                  <CDropdownItem v-if="authStore.details.is_superuser"><a href="/admin/">{{ t('navbar.admin') }}</a></CDropdownItem>
                </template>
                <CDropdownItem><router-link @click=logout() to="/">{{ t('navbar.logout') }}</router-link></CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
            <CDropdown v-else-if="authStore.user && authStore.details && !authStore.details.registration_complete" togglerText="Menu" variant="nav-item">
              <CDropdownToggle togglerText="Dropdown">
              <CButton>{{ t('navbar.menu') }}</CButton>
              </CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem><router-link to="/feedback/">{{ t('navbar.feedback') }}</router-link></CDropdownItem>
                <CDropdownItem><router-link @click=logout() to="/">{{ t('navbar.logout') }}</router-link></CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
            <CDropdown v-else togglerText="Menu" variant="nav-item">
              <CDropdownToggle togglerText="Dropdown">
                <CButton>{{ t('navbar.menu') }}</CButton>
              </CDropdownToggle>
              <CDropdownMenu>
                <CDropdownItem><router-link to="/login">{{ t('navbar.login') }}</router-link></CDropdownItem>
                <CDropdownItem><router-link to="/register/">{{ t('navbar.register') }}</router-link></CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
            <CDropdown toggerText="Language" variant="nav-item">
              <CDropdownToggle togglerText="Dropdown">
              <CButton>
                <country-flag v-if="getFlag(locale)" :country="getFlag(locale)" size="normal"/>
                {{ languageDisplay(locale) }}
              </CButton>
              </CDropdownToggle>
              <CDropdownMenu class="overflow-auto">
                <CDropdownItem  v-for="language in languages" :key="language" @click="updateLanguage(language)">
                  <CDropdownDivider/>
                  <country-flag v-if="getFlag(language)" :country="getFlag(language)" size="normal" />
                  {{ languageDisplay(language) }}
                </CDropdownItem>
              </CDropdownMenu>
            </CDropdown>
          </CHeaderNav>
        </CCollapse>
      </CContainer>
    </CHeader>
  </header>
</template>

<style scoped>
  p {
    font-size: 20px;
  }
  * >>> div.overflow-auto {
    height: 45vh !important;
  }

</style>
