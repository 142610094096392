<script setup>
  import { ref, defineProps } from 'vue'
  import { useRouter } from 'vue-router'
  const router = useRouter()
  import RewardAnimation from '@/components/RewardAnimation.vue'
  import { useI18n } from 'vue-i18n'

  const messages = ref([])
  const isOpen = ref(false)
  const title = ref("")
  const notification_type = ref("")
  const body = ref("")
  const should_animate = ref(false)
  const reward_animation = ref(false)
  const { t } = useI18n()
  
  function showMessage(message) {
    messages.value.push(message)
    showNextMessage()
  }

  function showNextMessage() {
    console.log("showNextMessage")
    if (isOpen.value) {
      console.log("isOpen")
      return
    }
    const message = messages.value.shift()
    if (!message) {
      console.log("no message")
      return
    }
    console.log("showing message", message)
    notification_type.value = message.notification_type
    title.value = message.title
    body.value = message.body
    console.log("reward is", reward_animation.value)
    should_animate.value = message.meta.animation
    isOpen.value = true
  }

  function close() {
    isOpen.value = false
    setTimeout(() => {
      console.log("timer")
      showNextMessage()
    }, 500)
  }

  function go_to_billing() {
    close()
    router.push({ name: 'BillingView' })
  }

  defineExpose({
    showMessage
  })

</script>
<template>
  <CModal :visible="isOpen" @close="close">
    <CModalHeader>
    <CModalTitle>{{ title }}</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <span v-if="notification_type === 'daily_challenge'">
        {{ body }}
      </span>
      <span v-else>
        {{ body }}
      </span>
    </CModalBody>
    <CModalFooter>
      <CButton v-if="notification_type == 'credits_used_up'" @click="go_to_billing">{{ t('cards.subscribe-now') }}</CButton>
      <CButton v-else @click="close">{{ t('cards.keep-studying') }}</CButton>
    </CModalFooter>
    <reward-animation v-if="should_animate" ref="reward_animation">
    </reward-animation>
  </CModal>
</template>
<style>
</style>

