<script setup lang="ts">
  import { ref, provide, onMounted  } from 'vue'
  import { IonPage, IonHeader, IonToolbar, IonContent, onIonViewWillEnter } from '@ionic/vue';
  import SubRouter from "@/components/SubRouter.vue";
  import RewardNotifications from "@/components/RewardNotifications.vue";
  const key = ref(0)
  const reward_notifications = ref("reward_notifications")
  provide('reward_notifications', reward_notifications)
  const first = ref(true)
  onIonViewWillEnter(async () => {
    // if we don't gate the first call, the sub-router will be re-created and we will end up mounting every component twice
    if (first.value) {
      first.value = false
      return
    }
    key.value += 1
  })
</script>

<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
        </ion-toolbar>
      </ion-header>
      <reward-notifications ref="reward_notifications">
      </reward-notifications>
      <sub-router name="sub" :key="key" />
    </ion-content>
  </ion-page>
</template>
