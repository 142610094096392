<template>
  <progress-bar
    :yellow="yellow"
    :green="green"
    :red="red"
    :blue="blue"
    />
</template>

<script setup>
import { defineProps } from 'vue';
import ProgressBar from './ProgressBar.vue';

const props = defineProps({
  progress: {
    type: Object,
    required: true,
  },
})

const total = (
  props.progress.new
  + props.progress.learning
  + props.progress.review_not_due
  + props.progress.review_due
  + props.progress.relearning
)
const blue = (props.progress.new / total) * 100
const yellow = (props.progress.learning / total) * 100
const green = ((props.progress.review_not_due) / total) * 100
const red = ((props.progress.relearning + props.progress.review_due) / total) * 100
</script>
