<script setup>
  import { useProfileStore } from '@/stores/profile.store'
  import { defineEmits, onMounted, ref } from 'vue'
  import { useI18n } from 'vue-i18n'
  const { t } = useI18n()
  const profileStore = useProfileStore()
  const emit = defineEmits(['changeLanguage'])
  const loaded = ref(false)
  onMounted(async() => {
    await profileStore.fetchProfile()
    loaded.value = true
  })
  async function change_learning_language(language) {
    await profileStore.changeLanguage(language)
    emit('changeLanguage', language)
  }
</script>
<template>
  <div class="row align-items-center justify-content-center py-1">
    <div class="col-6">
      <b>{{ t('cards.currently-studying') }}</b>
    </div>
    <div v-if="loaded" class="col-6">
      <select class="form-select" id="level" v-model="profileStore.profile.learning_language" @change="event => change_learning_language(event.target.value)">
        <option v-for="language in profileStore.profile.languages" v-bind:key="language.id" v-bind:value="language.language">
          {{ t(`language.name.${language.language_id}`) }}
        </option>
      </select>
    </div>
  </div>
</template>
