<script setup>
  import { ref, onMounted, inject } from 'vue'
  import { fetchWrapper } from '@/helpers/fetch-wrapper'
  import { useI18n } from 'vue-i18n'
  import { marked } from 'marked'
  import { CTooltip } from '@coreui/vue-pro'
  const { t } = useI18n()
  import { useRouter } from 'vue-router'
  import { capitalizeFirstLetter, markTourViewed } from '@/helpers/utils'
  import FlashcardGroupProgressBar from '@/components/FlashcardGroupProgressBar.vue'
  const router = useRouter()
  // we need to import the router to get the category id from the url
  const group_list = ref([])
  const subscribed_lists = ref([])
  const isLoading = ref(true)
  const topContent = ref(null)
  const generating = ref(false)
  async function load_group_list() {
    console.log('load_group_list')
    const response = await fetchWrapper.get(`/flashcard/group/list/`)
    group_list.value = response
    // filter group.list values to get items with subscribed === true and put this in subscribed_lists.value
    subscribed_lists.value = group_list.value.filter(group => group.subscribed === true)
    // if group_list.value length is empty, set generating to true
    if (group_list.value.length === 0) {
      generating.value = true
    }
  }
  const tour = inject('flashcardTour')
  var elemEventHandler = function () {
    tour.cancel()
  }
	tour.on('complete', () => {
    setTimeout(() => {
      topContent.value.scrollIntoView({ behaviour: 'smooth'})
    }, 500)
	})
	tour.on('cancel', () => {
    setTimeout(() => {
      topContent.value.scrollIntoView({ behaviour: 'smooth'})
    }, 500)
	})
  tour.on('show', (t) => {
    setTimeout(() => {
      t.step.target.addEventListener('click', elemEventHandler, false)
    }, 500)
  })
	const tourStep1Text = t('tour.flashcards-are-a-great-way-to-pick-up')
	const tourStep2Text = t('tour.here-you-will-find-a-number-of-decks')
  onMounted(async () => {
    await load_group_list()
    console.log('load_group_list done')
    isLoading.value = false
    const response = await fetchWrapper.get(`/profile/`)
    console.log('response', response)
    console.log('response.shown_flashcard_tour', response.shown_flashcard_tour)
    if (!response.shown_flashcard_tour) {
      console.log('start tour')
      tour.start()
      markTourViewed('flashcard')
    }
  })
  function subscribe(group) {
    fetchWrapper.post(`/flashcard/group/subscribe/`, {groupID: group.id})
    group.subscribed = true
  }
  function unsubscribe(group) {
    fetchWrapper.post(`/flashcard/group/unsubscribe/`, {groupID: group.id})
    group.subscribed = false
  }
  function view_group (groupId) {
    router.push({name: 'CardsList', params: {groupID: groupId } })
    resetInputs()
  }
  function study_group (groupId) {
    router.push({name: 'CardsView', params: {groupID: groupId } })
    resetInputs()
  }
  function resetInputs() {
    // find all radio inputs and set their value to false
    const inputs = document.querySelectorAll('input[type="radio"]')
    inputs.forEach(input => {
      input.checked = false
    })
  }

  async function change_learning_language() {
    isLoading.value = true
    await load_group_list()
    isLoading.value = false
  }
</script>

<template>
  <nice-header />
  <section class="my-1">
    <div class="container" ref="topContent">
      <div class="row">
        <div class="col-12">
          <div class="bg-white border-0 rounded border-light p-1 p-sm-2 p-md-5 w-100 fmxw-500">
            <div class="text-center mb-4 mt-md-0 col-12">
              <h1 class="mb-0 h1-title pb-3">{{ t('navbar.flashcards') }}</h1>
              <hr class="my-1"
                v-tour-step:1="{
                  tour: flashcardTour,
                  options: {
                    attachTo: { on: 'top' },
                    text: tourStep1Text,
                    buttons: [
                    {
                      classes: 'btn btn-primary btn-primary-cs btn-primary-sm',
                      text: 'Next',
                      action: flashcardTour.next,
                    },
                    ],
                  }
                }"
							/>
              <loading-overlay v-model:active="isLoading" :can-cancel="true" is_full_page="false" loader="dots" :opacity="0.8" />
                <!--
              <div class="col-12 text-left pt-5 flashcardsContentBlock" v-html="marked.parse(t('content-block.text.2'))">
              </div>
                -->
            </div>
            <template v-if="isLoading === false && generating === false">
              <div class="row py-1 align-items-center justify-content-between">
                <div class="col-md-6 text-left">
                  <current-language @changeLanguage="change_learning_language" />
                </div>
                <div v-if="subscribed_lists.length > 0" class="col-md-6 text-md-end mt-4 mt-md-0">
                  <router-link v-if="subscribed_lists.length > 0" :to="{name: 'CardsView', params: {groupID: 'all' } }">
                    <CButton class="btn-primary-cs btn-primary-sm" color="primary">{{ t('cards.study-all') }}</CButton>
                  </router-link>
                </div>
                <div class="col-12 alert alert-success" v-else>
                  {{ t('cards.you-are-not-currently-subscribed-to-any-flashcard') }}
                </div>
              </div>
              <div
                v-tour-step:2="{
                tour: flashcardTour,
                options: {
                  attachTo: { on: 'top' },
                  text: tourStep2Text,
                  buttons: [
                  {
                    classes: 'btn btn-primary btn-primary-cs btn-primary-sm',
                  text: 'Done',
                  action: flashcardTour.next,
                  },
                  ],
                }
                }"
                />
              <div class="row mt-4">
                <div class="col-12">
                  <div class="responsive">
                    <CContainer class="d-none d-xl-block p-0 table-cards-cs">
                      <CRow class="bold theadings justify-content-between text-start">
                        <CCol class="col-lg-2 text-center">{{ t('cards.name') }}</CCol>
                        <CCol class="col-lg-2 text-center">{{ t('cards.reviews-due') }}</CCol>
                        <CCol class="col-lg-4 text-center">{{ t('cards.actions') }}</CCol>
                      </CRow>
                      <template v-for="group in group_list" :key="group.id">
                        <CRow class="tbodyRow justify-content-between text-start">
                          <CCol class="col-lg-2 text-center">{{ capitalizeFirstLetter(t(`flashcard-subject.subject.${group.subject_id}`)) }}</CCol>
                          <CCol class="col-lg-2 text-center">
                            <CTooltip :content="t('cards.remaining-reviews', { remaining: group.due })">
                              <template #toggler="{ id, on }">
                                <span class="d-inline-block" :tabindex="0" :aria-describedby="id" v-on="on">
                                  <flashcard-group-progress-bar :progress="group.progress" />
                                </span>
                              </template>
                            </CTooltip>
                          </CCol>
                          <CCol class="col-lg-4">
                            <CInputGroup>
                              <CButtonGroup role="group" aria-label="Basic checkbox toggle button group">
                                <CFormCheck :id="group.subject_id + 'check_unsub'" @click="unsubscribe(group)" v-if="group.subscribed" type="radio" :label="t('cards.unsubscribe')" :button="{color: 'primary', variant: 'outline', class: 'btn-grp btn-primary-grp'}"></CFormCheck>
                                <CFormCheck :id="group.subject_id + 'check_sub'" @click="subscribe(group)"   v-else type="radio" :label="t('cards.subscribe')" :button="{color: 'primary', variant: 'outline', class: 'btn-grp btn-outline-grp'}"></CFormCheck>
                                <CFormCheck :id="group.subject_id + 'view'" @click="view_group(group.id)" type="radio" :label="t('cards.view')" :button="{color: 'primary', variant: 'outline', class: 'btn-grp btn-outline-grp'}"></CFormCheck>
                                <CFormCheck :id="group.subject_id + 'study'" @click="study_group(group.id)" type="radio" :label="t('cards.study')" :button="{color: 'primary', variant: 'outline', class: 'btn-grp btn-outline-grp'}"></CFormCheck>
                              </CButtonGroup>
                            </CInputGroup>
                          </CCol>
                        </CRow>
                      </template>
                    </CContainer>
                    <CContainer class="d-xl-none d-xxl-none p-0 table-cards-cs table-card-sm">
                      <CRow class="theadings">
                        <CCol class="col-xs-6"><b>{{ t('cards.name') }}</b></CCol>
                        <CCol class="col-xs-6">
                          <b>{{ t('cards.reviews-due') }}</b>
                        </CCol>
                      </CRow>
                      <template v-for="group in group_list" :key="group.id">
                        <div class="tbodyRow">
                          <CRow class="mb-2">
                            <CCol class="col-xs-6 text-center">{{ capitalizeFirstLetter(t(`flashcard-subject.subject.${group.subject_id}`)) }}</CCol>
                            <CCol class="col-xs-6 text-center">
                            <CTooltip :content="t('cards.remaining-reviews', { remaining: group.due })">
                              <template #toggler="{ id, on }">
                                <span class="d-inline-block" :tabindex="0" :aria-describedby="id" v-on="on">
                                  <flashcard-group-progress-bar :progress="group.progress" />
                                </span>
                              </template>
                            </CTooltip>
                            </CCol>
                          </CRow>
                          <CRow class="mb-2">
                            <CCol class="col-md-12 justify-content-center">
                              <CInputGroup class="justify-content-center mt-3">
                                <CButtonGroup role="group" aria-label="Basic checkbox toggle button group">
                                  <CFormCheck :id="group.subject_id + 'check_unsub'" @click="unsubscribe(group)" v-if="group.subscribed" type="radio" :label="t('cards.unsubscribe')" :button="{color: 'primary', variant: 'outline', class: 'btn-grp btn-primary-grp'}"></CFormCheck>
                                  <CFormCheck :id="group.subject_id + 'check_sub'" @click="subscribe(group)"   v-else type="radio" :label="t('cards.subscribe')" :button="{color: 'primary', variant: 'outline', class: 'btn-grp btn-outline-grp'}"></CFormCheck>
                                  <CFormCheck :id="group.subject_id + 'view'" @click="view_group(group.id)"  type="radio" :label="t('cards.view')" :button="{color: 'primary', variant: 'outline', class: 'btn-grp btn-outline-grp'}"></CFormCheck>
                                  <CFormCheck :id="group.subject_id + 'study'" @click="study_group(group.id)" type="radio" :label="t('cards.study')" :button="{color: 'primary', variant: 'outline', class: 'btn-grp btn-outline-grp'}"></CFormCheck>
                                </CButtonGroup>
                              </CInputGroup>
                            </CCol>
                          </CRow>
                        </div>
                      </template>
                    </CContainer>
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="isLoading === false && generating === true">
              <div class="row mt-4">
                <div class="col-12">
                  <div class="responsive">
                    <CContainer class="p-0 table-cards-cs">
                      <CRow class="bold theadings justify-content-between text-start">
                        <CCol class="col-xs-12 col-sm-12 col-md-6 offset-md-3 col-lg-6 offset-lg-3 text-center">{{ t('cards.new-language-combination-detected') }}</CCol>
                      </CRow>
                      <CRow class="justify-content-between text-start">
                        <CCol class="col-xs-12 col-sm-12 col-md-6 offset-md-3 col-lg-6 offset-lg-3 text-center">{{ t('cards.you-have-selected-a-language-combination-we-dont') }}</CCol>
                      </CRow>
                      <CRow class="justify-content-between text-start mt-3">
                      <router-link to="/chat">
                        <CCol class="col-xs-12 col-sm-12 col-md-6 offset-md-3 col-lg-6 offset-lg-3 text-center">{{ t('cards.please-chat-with-your-ai-tutor-in-the') }}</CCol>
                      </router-link>
                      </CRow>
                    </CContainer>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </section>
  <nice-footer />
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.question {
  height: 100px;
}
.answer {
  height: 100px;
}
.text-left {
  text-align: left;
}
#centerTable td, th {
  text-align: center;
}
.bold div  {
  font-weight: bold;
}
div.btn-group >>> label.btn {
  min-width: 100px;
}
</style>


