<script setup>
  import { ref, defineProps, onMounted } from 'vue'
  import { useReward } from 'vue-rewards'
  import { fireHaptic } from '@/helpers/utils'
  import { useProfileStore } from '@/stores/profile.store'


  const props = defineProps({
    type: String, // confetti, balloons, emojis
    disableHaptic: Boolean, // defaults to false
    severity: String // major or minor, defaults to major
  })
  const profileStore = useProfileStore()

  const elementId = ref(randomString(8))
  var useAnimationType
  if (props.type == 'random') {
    const types = ['confetti', 'balloons', 'emoji']
    const randomType = types[Math.floor(Math.random() * types.length)]
    useAnimationType = randomType
  } else {
    useAnimationType = props.type || "confetti"
  }
  const animationType = ref(useAnimationType)
  const disableHaptic = ref(props.disableHaptic || false)
  const severity = ref(props.severity || "major")
  const options = {
    zIndex: 2147483647,
  }
  // halve display time and count of rewards if they are minor
  console.log('severity is currently', severity.value, 'useAnimationType', animationType.value)
  if (severity.value === 'minor') {
    if (animationType.value === 'confetti') {
      options['elementCount'] = 25
      options['lifetime'] = 100
    } else if (animationType.value === 'balloons') {
      options['elementCount'] = 5
      options['lifetime'] = 100
    } else if (animationType.value === 'emoji') {
      options['elementCount'] = 10
      options['lifetime'] = 100
    }
  }

  console.log('reward animation options', options)

  const { reward : confettiReward } = useReward(elementId.value, animationType.value, options)

  function randomString(length) {
    var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split('');
    if (!length) {
      length = Math.floor(Math.random() * chars.length);
    }
    var str = '';
    for (var i = 0; i < length; i++) {
      str += chars[Math.floor(Math.random() * chars.length)];
    }
    return str;
  }

  const animate = () => {
    if (!disableHaptic.value) {
      fireHaptic()
    }
    confettiReward()
  }
  onMounted(() => {
    profileStore.fetchProfile()
    console.log(profileStore.profile)
    if (!profileStore.profile.show_animations) {
      console.log('no animations from profile setting')
      return
    }
    if (!disableHaptic.value) {
      fireHaptic()
    }
    confettiReward()
  })

  defineExpose({
    animate,
  })

</script>


<template>
  <div :id="elementId">
    <slot name="body" />
  </div>
</template>
<style>
</style>
