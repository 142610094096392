import { createRouter, createWebHistory } from '@ionic/vue-router';
import { useAuthStore} from '@/stores/auth.store'
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'
import LoginForm from '@/views/LoginForm.vue'
import RegisterForm from '@/views/RegisterForm.vue'
import VerifyEmail from '@/views/VerifyEmail.vue'
import SupportForm from '@/views/SupportForm.vue'
import DeleteForm from '@/views/DeleteForm.vue'
import ResetPassword from '@/views/ResetPassword.vue'
import ForgotPassword from '@/views/ForgotPassword.vue'

import ProfileView from '@/views/ProfileView.vue'
import BillingView from '@/views/BillingView.vue'
import LanguageConfig from '@/views/LanguageConfig.vue'
import Tab2Page from '@/views/Tab2Page.vue'

import HomeView from '@/views/HomeView.vue'
import HelpView from '@/views/HelpView.vue'
import TermsView from '@/views/TermsView.vue'
import PrivacyView from '@/views/PrivacyView.vue'

import { fetchWrapper } from '@/helpers/fetch-wrapper'
import { isApp } from '@/helpers/utils.js'

var routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomeView',
    meta: { title: 'Lingo.ing Home' },
    component: HomeView
  },
  {
    path: '/help',
    name: 'HelpView',
    meta: { title: 'Lingo.ing Help' },
    component: HelpView
  },
  // sometimes the PWA service worker will intercept /admin/, but this makes it hit the django server instead
  {
    path: '/admin/*',
    beforeEnter() {
      window.location.href = '/admin/'
    }
  },
  {
    path: '/debug-capacitor-plugin',
    component: () => import('@/views/DebugCapacitorPlugin.vue')
  },
  {
    path: '/debug-audio',
    component: () => import('@/views/DebugAudio.vue')
  },
  {
    path: '/debug-audio2',
    component: () => import('@/views/DebugAudio2.vue')
  },
  {
    path: '/terms-of-use',
    name: 'TermsOfUse',
    meta: { title: 'Lingo.ing Terms Of Use' },
    component: TermsView
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    meta: { title: 'Lingo.ing - Privacy Policy' },
    component: PrivacyView
  },
  {
    path: '/privacy-policy/',
    name: 'PrivacyPolicy',
    meta: { title: 'Lingo.ing - Privacy Policy' },
    component: PrivacyView
  },
  {
    path: '/login',
    name: 'LoginForm',
    component: LoginForm
  },
  {
    path: '/forgot-password/',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/reset-password/:validation_user/:validation_token/',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/register/:validation_user/:validation_token/',
    name: 'EmailValidateLegacy',
    component: VerifyEmail
  },
  {
    path: '/register',
    name: 'RegisterForm',
    component: RegisterForm
  },
  {
    path: '/support',
    name: 'SupportForm',
    component: SupportForm
  },
  {
    path: '/data-deletion/',
    name: 'DeleteForm',
    component: DeleteForm
  },
  {
    path: '/booga',
    component: TabsPage,
    children: [
      {
        path: '/docs/',
        name: 'Documentation',
        meta: { title: 'Flashcards - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/docs/Index.vue')
        },
      },
      {
        path: '/docs/flashcards/',
        name: 'Flashcards Documentation',
        meta: { title: 'Flashcards - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/docs/Flashcards.vue')
        },
      },
      {
        path: '/docs/profile/',
        name: 'Profile Documentation',
        meta: { title: 'Profile - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/docs/ProfileDocs.vue')
        },
      },
      {
        path: '/docs/chat/',
        name: 'Chat Documentation',
        meta: { title: 'Docs - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/docs/Chat.vue')
        },
      },
      {
        path: '/docs/vocabulary/',
        name: 'Vocabulary Documentation',
        meta: { title: 'Docs - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/docs/UsingVocabulary.vue')
        },
      },
      {
        path: '/docs/reading-together/',
        name: 'Reading Together Documentation',
        meta: { title: 'Docs - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/docs/ReadingTogether.vue')
        },
      },
      {
        path: '/docs/courses/',
        name: 'Courses Documentation',
        meta: { title: 'Docs - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/docs/Courses.vue')
        },
      },
      {
        path: '/dashboard/',
        name: 'Dashboard',
        meta: { title: 'Dashboard - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/DashboardView.vue')
        },
      },
      {
        path: '/cards/',
        name: 'CardCategories',
        meta: { title: 'Card Categories - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/CardCategories.vue')
        },
      },
      {
        path: '/cards/:groupID/list/',
        name: 'CardsList',
        meta: { title: 'Cards - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/CardsListTable.vue')
        }
      },
      {
        path: '/cards/:groupID/',
        name: 'CardsView',
        meta: { title: 'Cards - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/CardsView.vue')
        },
      },
      {
        path: '/vocab/add/',
        name: 'AddVocab',
        meta: { title: 'Add Vocab - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/VocabAdd.vue')
        },
      },
      {
        path: '/vocab/recent/',
        name: 'RecentVocabEntry',
        meta: { title: 'Cards - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/RecentVocabList.vue')
        },
      },
      {
        path: '/vocab/',
        name: 'VocabEntry',
        meta: { title: 'Cards - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/VocabList.vue')
        },
      },
      {
        path: '/vocab/edit/:id/',
        name: 'EditVocab',
        meta: { title: 'Edit Vocab - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/EditVocab.vue')
        },
      },
      {
        path: '/profile/',
        name: 'ProfileView',
        meta: { title: 'Profile - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: ProfileView
        },
      },
      {
        path: '/profile/change-password/',
        name: 'ChangePassword',
        meta: { title: 'Profile - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/ChangePassword.vue')
        },
      },
      {
        path: '/profile/language/:languageID/',
        name: 'LanguageConfig',
        meta: { title: 'Profile - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: LanguageConfig
        },
      },
      {
        path: '/billing/',
        name: 'BillingView',
        meta: { title: 'Billing - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: BillingView
        },
      },
      {
        path: '/course/',
        name: 'CourseView',
        meta: { title: 'Study - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/CourseView.vue')
        },
      },
      {
        path: '/course/tutorial/',
        name: 'TutorialListView',
        components: {
          default: Tab2Page,
          sub: () => import('@/views/TutorialListView.vue')
        },
      },
      {
        path: '/course/tutorial/:language/',
        name: 'TutorialView',
        components: {
          default: Tab2Page,
          sub: () => import('@/views/TutorialView.vue')
        },
      },
      {
        path: '/course/tutorial/:language/:slug/',
        name: 'TutorialChapterView',
        components: {
          default: Tab2Page,
          sub: () => import('@/views/TutorialChapterView.vue')
        },
      },
      {
        path: '/chat/',
        name: 'ChatCategories',
        meta: { title: 'Categories - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/ChatCategories.vue')
        },
      },
      {
        path: '/chat/create/',
        name: 'ChatCreate',
        meta: { title: 'Chat - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/ChatCreate.vue')
        },
      },
      {
        path: '/chat/interface/',
        name: 'ChatInterface',
        meta: { title: 'Chat - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/ChatInterface.vue')
        },
      },
      {
        path: '/chat/interface/chat_id/:chatID/',
        name: 'ChatInterfaceChatID',
        meta: { title: 'Chat - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/ChatInterface.vue')
        },
      },
      {
        path: '/chat/interface/template_id/:templateID/',
        name: 'ChatInterfaceTemplateID',
        meta: { title: 'Chat - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/ChatCreate.vue')
        },
      },
      {
        path: '/chat/interface/template_id/:templateID/variant_id/:variantID/',
        name: 'ChatInterfaceTemplateIDVariantID',
        meta: { title: 'Chat - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/ChatCreate.vue')
        },
      },
      {
        path: '/chat/interface/template_id/:templateID/flashcard_entry_id/:flashcard_entryID/',
        name: 'ChatInterfaceTemplateIDFlashcardEntryID',
        meta: { title: 'Chat - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/ChatCreate.vue')
        },
      },
      {
        path: '/chat/interface/template_id/:templateID/recent_vocab_id/:recent_vocabID/',
        name: 'ChatInterfaceTemplateIDRecentVocabID',
        meta: { title: 'Chat - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/ChatCreate.vue')
        },
      },
      {
        path: '/chat/interface/template_id/:templateID/vocab_entry_id/:vocab_entryID/',
        name: 'ChatInterfaceTemplateIDVocabEntryID',
        meta: { title: 'Chat - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/ChatCreate.vue')
        },
      },
      {
        path: '/chat/interface/template_id/:templateID/tutorial_chapter_id/:tutorial_chapterID/',
        name: 'ChatInterfaceTemplateIDTutorialChapterID',
        meta: { title: 'Chat - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/ChatCreate.vue')
        },
      },
      {
        path: '/chat/setup/',
        name: 'ChatSetup',
        meta: { title: 'Chat - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/ChatSetup.vue')
        },
      },
      {
        path: '/chat/setup/:templateID/',
        name: 'ChatSetupTemplateID',
        meta: { title: 'Chat - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/ChatSetup.vue')
        },
      },
      {
        path: '/chat/templates/:categoryID/',
        name: 'ChatTemplates',
        components: {
          default: Tab2Page,
          sub: () => import('@/views/ChatTemplates.vue')
        },
      },
      {
        path: '/chat/history/',
        name: 'ChatHistory',
        meta: { title: 'Chat History - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/ChatHistory.vue')
        },
      },
      {
        path: '/article/list/',
        name: 'ArticleList',
        meta: { title: 'Articles - Lingo.ing' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/ArticleList.vue')
        },
      },
      {
        path: '/feedback',
        name: 'FeedbackForm',
        meta: { title: 'Feedback' },
        components: {
          default: Tab2Page,
          sub: () => import('@/views/FeedbackForm.vue')
        }
      },
    ]
  },
]

if (isApp) {
  //iterate routes and remove entry for /
  routes = routes.filter(route => route.path !== '/')
  routes.push({
    path: '/',
    name: 'RegisterForm',
    component: RegisterForm
  })
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

function match_path(path: string, condition: Object) {
  if (typeof condition === 'string') {
    return path === condition
  }
  return condition.test(path)
}

router.beforeEach(async (to) => {
  // redirect to login page if not logged in
  const publicPages = [
    '/',
    '/login',
    '/register/',
    '/support/',
    '/forgot-password/',
    '/terms-of-use',
    '/privacy-policy',
    '/privacy-policy/',
    '/help',
    new RegExp("^/register/[0-9]+/[a-z]+/?$"),
    new RegExp("^/email-validate/[0-9]+/[a-z]+/?$"),
    new RegExp("^/reset-password/[0-9]+/[a-z]+/?$"),
  ]
  const paidPages = [
    new RegExp("^/articles"),
    new RegExp("^/chat/"),
    new RegExp("^/cards"),
    new RegExp("^/course"),
    new RegExp("^/vocab"),
  ]
  var authRequired = true
  var paymentRequired = false
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  else {
    document.title = 'Lingo.ing'
  }
  // iterate throguh public pages, if the list item is a string, then look for an exact match.
  // if the list item is a regex, then test the regex against the path
  // if either match as positive, set the authRequired value to false
  for (var i = 0; i < publicPages.length; i++) {
    if (match_path(to.path, publicPages[i])) {
      authRequired = false
    }
  }
  for (var i = 0; i < paidPages.length; i++) {
    if (match_path(to.path, paidPages[i])) {
      paymentRequired = true
    }
  }
  console.log('router debug', to.path, authRequired, paymentRequired)

  var auth = useAuthStore()

  if (to.path === '/login') {
    auth.returnUrl = undefined
  }
  if (authRequired && !auth.user) {
    // auth.returnUrl = to.fullPath AE this might be causing fussiness with broekn auth
    // i think it may be over-written several times during a 401/403 chain
    auth.returnUrl = undefined
    return '/login'
  }
  if (authRequired) {
    try {
      await auth.getUserDetails(true)
    } catch(error) {
      console.log('error on userDetails', error)
      return '/login'
    }
    if (paymentRequired && auth.details.billing_locked) {
      return '/billing'
    }
  }
})



export default router
